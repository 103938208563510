import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { API_ROUTES } from 'src/routes/api';
import { DEFAULT_PAGE_SIZE } from 'src/config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  yachtsList: {
    data: null,
    pagination: {}
  },
  activeFilters: {}
};

const slice = createSlice({
  name: 'reservationYachts',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.yachtsList = {
        data: null,
        pagination: {}
      };
    },

    // GET YACHTS
    getYachtsSuccess(state, action) {
      state.isLoading = false;
      state.yachtsList = {
        data: action.payload.yachts,
        pagination: action.payload.meta.pagination
      };
    },

    resetYachtsList(state) {
      state.yachtsList = {
        data: null,
        pagination: {}
      };
    },

    setActiveFilters(state, action) {
      state.activeFilters = action.payload;
    },

    reset(state) {
      Object.keys(state).forEach((key) => (state[key] = initialState[key]));
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { resetYachtsList } = slice.actions;

// ----------------------------------------------------------------------

export function getYachtsList(page = 1, page_size = DEFAULT_PAGE_SIZE, filters) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const state = getState();

    const activeFilters = filters || state.reservationYachts.activeFilters;

    try {
      const {
        data: { yachts, meta }
      } = await axios.get(API_ROUTES.yachts.available, {
        params: {
          page,
          page_size,
          ...activeFilters
        }
      });
      dispatch(slice.actions.getYachtsSuccess({ yachts, meta }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function refetchYachtsList() {
  return async (dispatch, getState) => {
    const { reservationYachts } = getState();
    const { currentPage, pageSize } = reservationYachts.yachtsList.pagination;

    dispatch(getYachtsList(currentPage, pageSize));
  };
}

export function setActiveFilters(activeFilters) {
  return async (dispatch, getState) => {
    const { reservationYachts } = getState();
    const { pageSize } = reservationYachts.yachtsList.pagination;

    dispatch(slice.actions.setActiveFilters(activeFilters));

    await dispatch(getYachtsList(1, pageSize, activeFilters));
  };
}

export function reset() {
  return async (dispatch) => {
    dispatch(slice.actions.reset());
  };
}
