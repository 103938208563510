// locales
import i18n from 'src/locales/i18n';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
import GradingIcon from '@mui/icons-material/Grading';
import { BugReport } from '@mui/icons-material';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  elements: getIcon('ic_elements'),
  settings: getIcon('ic_settings'),
  reportProblem: getIcon('ic_report-problem'),
  specialOffers: getIcon('ic_special-offer')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: i18n.t('ogólne'),
    items: [
      {
        title: i18n.t('pulpit'),
        path: PATH_DASHBOARD.general.app,
        external: false,
        icon: ICONS.dashboard
      }
      //      {
      //        title: i18n.t("płatności"),
      //        path: PATH_DASHBOARD.general.banking,
      //        icon: ICONS.ecommerce,
      //      },
      //      {
      //        title: i18n.t("raporty"),
      //        path: PATH_DASHBOARD.general.analytics,
      //        icon: ICONS.analytics,
      //      },
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: i18n.t('zarządzanie'),
    items: [
      //MANAGEMENT : RESEVATIONS
      {
        title: i18n.t('rezerwacje'),
        path: PATH_DASHBOARD.reservations.root,
        external: false,
        icon: ICONS.booking,
        children: [
          { title: i18n.t('Lista rezerwacji'), path: PATH_DASHBOARD.reservations.list, external: false },
          { title: i18n.t('Grafik rezerwacji'), path: PATH_DASHBOARD.reservations.matrix, external: false }
          //          { title: i18n.t("Ostatnio utworzone"), path: PATH_DASHBOARD.reservations.list },
          //          { title: i18n.t("Oczekujące na płatność"), path: PATH_DASHBOARD.reservations.list },
        ]
      },

      // MANAGEMENT : USER
      //     {
      //       title: i18n.t("klienci"),
      //       path: PATH_DASHBOARD.user.root,
      //       icon: ICONS.user,
      //       children: [
      //         { title: "profile", path: PATH_DASHBOARD.user.profile },
      //         { title: "cards", path: PATH_DASHBOARD.user.cards },
      //         { title: "list", path: PATH_DASHBOARD.user.list },
      //         { title: "create", path: PATH_DASHBOARD.user.newUser },
      //         { title: "edit", path: PATH_DASHBOARD.user.editById },
      //         { title: "account", path: PATH_DASHBOARD.user.account },
      //       ],
      //     },

      // MANAGEMENT : YACHTS
      {
        title: i18n.t('jachty'),
        path: PATH_DASHBOARD.yachts.root,
        icon: ICONS.elements,
        children: [
          { title: i18n.t('Lista jachtów'), path: PATH_DASHBOARD.yachts.list, external: false },
          {
            title: i18n.t('Dodaj nowy jacht'),
            path: PATH_DASHBOARD.yachts.create,
            external: false
          }
        ]
      },

      // SPECIAL OFFERS
      {
        title: i18n.t('Oferty specjalne'),
        path: PATH_DASHBOARD.specialOffers,
        external: false,
        icon: ICONS.specialOffers
      }

      // MANAGEMENT : INVOICES
      //      {
      //        title: i18n.t("faktury"),
      //        path: PATH_DASHBOARD.invoice.root,
      //        icon: ICONS.banking,
      //        children: [
      //          { title: i18n.t("Lista faktur"), path: PATH_DASHBOARD.invoice.root },
      //        ],
      //      },

      // MANAGEMENT : E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
      //   ]
      // },

      // MANAGEMENT : BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
      //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost }
      //   ]
      // }
    ]
  },

  {
    subheader: i18n.t('RAPORTY'),
    items: [
      {
        title: i18n.t('Przekazanie jachtów'),
        path: PATH_DASHBOARD.reports.bosuns,
        external: false,
        icon: <GradingIcon />
      },
      {
        title: i18n.t('Sprzątanie jachtów'),
        path: PATH_DASHBOARD.reports.cleaningService,
        external: false,
        icon: <GradingIcon />
      }
    ]
  },

  {
    subheader: i18n.t('ZEGLUJ.PL'),
    items: [
      {
        title: i18n.t('Wypłaty'),
        path: PATH_DASHBOARD.zegluj.payouts,
        external: false,
        icon: ICONS.ecommerce
      }
    ]
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: i18n.t('inne'),
    items: [
      //      {
      //        title: i18n.t("zapytania"),
      //        path: PATH_DASHBOARD.mail.root,
      //        icon: ICONS.mail,
      //        // info: <Label color="error">2</Label>
      //      },
      //      {
      //        title: i18n.t("oferty"),
      //        path: PATH_DASHBOARD.chat.root,
      //       icon: ICONS.chat,
      //      },
      //      {
      //        title: i18n.t("zadania"),
      //        path: PATH_DASHBOARD.calendar,
      //        icon: ICONS.calendar,
      //      },
      {
        title: i18n.t('ustawienia'),
        path: PATH_DASHBOARD.settings.root,
        external: false,
        icon: ICONS.settings,
        children: [
          {
            title: i18n.t('Szablony'),
            path: PATH_DASHBOARD.settings.templates
          }
        ]
      },
      {
        title: i18n.t('Zgłoś problem'),
        path: 'https://resgo.atlassian.net/servicedesk/customer/portal/1/group/1/create/10027',
        external: true,
        icon: <BugReport />
      }
    ]
  }
];

export default sidebarConfig;
